<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        route-name="rubric_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-rubric-filter></app-rubric-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="rubrics"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import { mapState } from 'vuex'
import Preloader from '../../components/preloader/Preloader'
import RubricFilter from '../../components/filter/FilterRubric'
import DataTable from '../../components/shared/DataTable'
import ButtonCreate from '../../components/shared/ButtonCreate'

export default {
  name: 'RubricListView',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          id: this.$t('rubric.list.id'),
          title: this.$t('rubric.list.title'),
          slug: this.$t('rubric.list.slug'),
          parent: this.$t('rubric.list.parent'),
          sorting: this.$t('rubric.list.sorting'),
          routes: this.$t('rubric.list.absolute_url')
        },
        actions: {
          edit: 'rubric_edit'
        },
        render: {
          routes: function (data) {
            if (data) {
              return data[0].absoluteUrl
            }
          },
          parent: function (data) {
            if (data) {
              return data
            }
            return '-'
          }
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    rubrics () {
      return this.$store.getters['rubric/list']
    },
    totalCount () {
      return this.$store.getters['rubric/totalCount']
    },
    page () {
      return this.$store.getters['rubric/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appRubricFilter: RubricFilter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    getRubrics () {
      this.$store.dispatch('rubric/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('rubric/setPage', page)
      this.getRubrics()
    }
  },
  mounted () {
    this.getRubrics()
  }
}
</script>

<style lang="scss" scoped>

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>
